<template>
    <div>
            <b-row>
                <b-col cols="12">
                        <b-form-group :label="$t('faculty')">
                            <div class="label-as-input">
                                {{ formData.faculty || '-' }}
                            </div>
                        </b-form-group>

                        <b-form-group :label="$t('program')">
                            <div class="label-as-input">
                                {{ formData.program || '-' }}
                            </div>
                        </b-form-group>

                        <b-form-group :label="$t('semester_1')">
                            <div class="label-as-input">
                                {{ formData.semester ? formData.semester.academic_year + ' / ' + formData.semester.semester : '-' }}
                            </div>
                        </b-form-group>

                        <b-form-group :label="$t('quota')">
                            <div class="label-as-input">
                                {{ isNotNullValue(formData.quota) }}
                            </div>
                        </b-form-group>

                        <b-form-group :label="$t('semester_count')">
                            {{ getObjectValue(formData,'semester_count') }}
                        </b-form-group>
                </b-col>
            </b-row>
    </div>
</template>

<script>
    // Services
    import DoubleMajorQuotaService from "@/services/DoubleMajorQuotaService";


    export default {
        props: {
            formId: {
                type: Number
            }
        },
        data() {
            return {
                formLoading: false,
                faculty_code: null,
                formData: {}
            }
        },
        async created() {
            await this.get()
        },
        methods: {
            get() {
                DoubleMajorQuotaService.detail(this.formId)
                                            .then(response => {
                                                let data = response.data.data
                                                this.formData = data
                                            })
                                            .catch(() => {
                                                this.$router.push('/404')
                                            });
            }
        }
    }
</script>
